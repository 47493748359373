import React, { useContext, useEffect, useRef, useState } from 'react';
import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Avatar from '@material-ui/core/Avatar';
import _get from 'lodash/get';
import _uniqBy from 'lodash/uniqBy';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { getUserFirstName, replacePlaceholders } from 'utils/stringUtils';
import {
  getSavedAssessments,
  getTakenAssessments,
} from 'containers/Assessments/actions';
import useSiteConfigSelector from 'components/useSiteConfigSelector';
import { getTakenSeries } from 'containers/Series/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getTakenCourses } from 'containers/Course/actions';
import {
  getTakenCourses as getTakenCoursesSelector,
  getAllCourses,
} from 'containers/Course/selectors';
import { getSavedResources } from 'containers/Resources/actions';
import { createStructuredSelector } from 'reselect';
import { getSavedTopics } from 'containers/Topics/actions';
import { makeSelectSavedTopics } from 'containers/Topics/selectors';
import {
  makeSelectSavedAssessments,
  makeSelectTakenAssessments,
} from 'containers/Assessments/selectors';
import {
  getAllSeries,
  getSeriesTakenInSession,
} from 'containers/Series/selectors';
import { makeSelectSavedResources } from 'containers/Resources/selectors';
import Mixpanel from 'utils/mixpanelService';
import NavBarContext from '../../navBarContext';
import { getActiveMonthsNumber } from '../../utils';
import ActivityStreakIcon from './icons/activity-streak.svg';
import InProgressIcon from './icons/in-progress.svg';
import ArrowRight from './icons/account-button-arrow-right.svg';
import AccountIcon from './AccountIcon';
import AccountSidebar from './AccountSidebar';

const THEME_MAPPING = {
  BLUE: {
    name: 'BLUE',
    backgroundColor: '#01619B',
    headerColor: '#FFFFFF',
    linkColor: '#A9DEFE',
    linkColor2: '#BADAED',
    separatorColor: 'rgba(255, 255, 255, 0.24)',
    borderColor: 'rgba(255, 255, 255, 0.10)',
  },
  DARK: {
    name: 'DARK',
    backgroundColor: '#4B4B4B',
    headerColor: '#FFFFFF',
    linkColor: '#A9DEFE',
    linkColor2: '#BADAED',
    separatorColor: 'rgba(255, 255, 255, 0.24)',
    borderColor: 'rgba(255, 255, 255, 0.10)',
  },
  LIGHT: {
    name: 'LIGHT',
    backgroundColor: '#E3E3E3',
    headerColor: '#4B4B4B',
    linkColor: '#01619B',
    linkColor2: '#4B4B4B',
    separatorColor: 'rgba(75, 75, 75, 0.24)',
    borderColor: 'rgba(255, 255, 255, 0.50)',
  },
};

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    background: 'linear-gradient(80.57deg, #E8FAFF -23.74%, #D3ECFF 122.1%)',
    padding: '8px 15px 8px 15px',
    height: 46,
    borderRadius: 23,
  },
  userWrapper: {
    display: 'flex',
    backgroundColor: '#FFFFFF',
    borderRadius: 15,
  },
  avatar: {
    height: 30,
    width: 30,
    color: theme.palette.text.primary,
  },
  username: {
    color: theme.palette.text.secondary,
    width: '100%',
    marginLeft: 10,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-flex',
    [theme.breakpoints.down('1200')]: {
      display: 'none',
    },
  },
  icon: {
    marginLeft: 5,
    paddingRight: 10,
  },
}));

const stateSelector = createStructuredSelector({
  takenAssessments: makeSelectTakenAssessments(),
  allSeries: getAllSeries,
  takenSeries: getSeriesTakenInSession,
  takenCourses: getTakenCoursesSelector,
  allCourses: getAllCourses,
  savedAssessments: makeSelectSavedAssessments(),
  savedResources: makeSelectSavedResources(),
  savedTopics: makeSelectSavedTopics(),
});

const AccountButton = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const anchorEl = useRef();
  const { clientDetails, profile, siteCopy } = useContext(NavBarContext);
  const [siteConfig] = useSiteConfigSelector(['Profile Sidebar']);
  const {
    takenAssessments,
    allCourses,
    allSeries,
    takenSeries,
    takenCourses,
    savedAssessments,
    savedResources,
    savedTopics,
  } = useSelector(stateSelector);

  useEffect(() => {
    dispatch(getTakenAssessments());
    dispatch(getTakenSeries());
    dispatch(getTakenCourses());
    dispatch(getSavedAssessments());
    dispatch(getSavedResources());
    dispatch(getSavedTopics());
  }, [dispatch]);

  const handleToggle = () => {
    setOpen(!open);
    if (!open)
      Mixpanel.track('User Menu - Clicked', {
        type: 'profile dashboard',
      });
  };

  const handleClose = event => {
    if (anchorEl.current && anchorEl.current.contains(event.target)) return;

    setOpen(false);
  };

  const { avatarUrl } = profile;
  const name = getUserFirstName(profile);
  const userName =
    name.length > 9
      ? name.slice(0, 6).endsWith(' ')
        ? name.slice(0, 5)
        : `${name.slice(0, 6)}...`
      : name;

  const isOnActivityStreak = getActiveMonthsNumber(profile) > 1;

  const inProgressSeries = Object.keys(takenSeries)
    .filter(key => !takenSeries[key].completed)
    .map(key => {
      const entry = allSeries.find(item => _get(item, 'sys.id') === key);
      const missingResourcesCount =
        _get(entry, 'fields.resources.length', 0) -
        _get(takenSeries[key], 'completedResourcesIds.length', 0);

      if (missingResourcesCount === 0) return null;
      if (!entry) return null;
      return {
        userData: { ...takenSeries[key], missingResourcesCount },
        fields: entry.fields,
        sys: entry.sys,
        type: 'series',
      };
    })
    .filter(item => !!item);

  const inProgressCourses = Object.keys(takenCourses)
    .filter(key => takenCourses[key].enrolled && !takenCourses[key].completed)
    .map(key => {
      const entry = allCourses.find(item => _get(item, 'sys.id') === key);
      const missingResourcesCount =
        _get(entry, 'fields.modules.length', 0) -
        _get(takenCourses[key], 'completedModuleIds.length', 0);

      if (missingResourcesCount === 0) return null;

      return {
        userData: { ...takenCourses[key], missingResourcesCount },
        fields: entry.fields,
        sys: entry.sys,
        type: 'courses',
      };
    })
    .filter(item => !!item);

  const parsedTakenAssessments = _uniqBy(
    takenAssessments.sort((a, b) => b.timestamp - a.timestamp),
    'assessment.slug',
  )
    .filter(item =>
      _get(siteConfig, 'config.assessments.eligible', []).includes(
        item.assessment.slug,
      ),
    )
    .map(item => ({
      ...item,
      shouldRetake: Date.now() - item.timestamp > 30 * 24 * 60 * 60 * 1000,
    }))
    .sort((a, b) => {
      if (a.shouldRetake && !b.shouldRetake) return -1;
      if (!a.shouldRetake && b.shouldRetake) return 1;
      return 0;
    });
  const retakeAssessmentsCount = parsedTakenAssessments.filter(
    item => item.shouldRetake,
  ).length;

  const inProgressResourcesCount =
    inProgressSeries.length + inProgressCourses.length;

  const activityStreakTooltip =
    isOnActivityStreak &&
    replacePlaceholders(
      _get(siteCopy, ['pageCopy', 'profile-sidebar', 'activityStreakTooltip']),
      {
        '#activeMonths': getActiveMonthsNumber(profile),
      },
    );

  const alertCount = inProgressResourcesCount + retakeAssessmentsCount;
  const hasInProgressResources = alertCount > 0;

  return (
    <>
      <ButtonBase
        aria-label="Avatar"
        onClick={handleToggle}
        data-test-id="profile-btn"
        disableRipple
      >
        <Box className={classes.wrapper} data-test-id="menu-account-section">
          {isOnActivityStreak && (
            <AccountIcon
              icon={ActivityStreakIcon}
              iconAlt="Activity Streak"
              tooltipLabel={activityStreakTooltip}
              enabled={!open}
            />
          )}
          {hasInProgressResources && (
            <AccountIcon
              icon={InProgressIcon}
              iconAlt="In Progress Resources"
              tooltipLabel={_get(siteCopy, [
                'pageCopy',
                'profile-sidebar',
                'inProgressResourcesTooltip',
              ])}
              topLabel={alertCount}
              enabled={!open}
            />
          )}
          <Box className={classes.userWrapper}>
            {avatarUrl ? (
              <Avatar
                alt="User Avatar"
                src={avatarUrl}
                className={classes.avatar}
              />
            ) : (
              <AccountCircle className={classes.avatar} />
            )}
            <Typography className={classes.username}>
              {userName}
              <img src={ArrowRight} alt="Open" className={classes.icon} />
            </Typography>
          </Box>
        </Box>
      </ButtonBase>
      <AccountSidebar
        open={open}
        onHide={handleClose}
        theme={
          THEME_MAPPING[_get(clientDetails, 'metadata.profileTheme')] ||
          THEME_MAPPING.BLUE
        }
        inProgressSeries={inProgressSeries}
        inProgressCourses={inProgressCourses}
        inProgressResourcesCount={inProgressResourcesCount}
        takenAssessments={parsedTakenAssessments}
        retakeAssessmentsCount={retakeAssessmentsCount}
        favoriteResources={savedResources
          .concat(
            savedTopics.map(item => ({
              name: item.fields.title,
              slug: item.fields.slug,
              objectID: item.sys.id,
              expandedType: 'Topics',
              timestamp: item.timestamp,
            })),
          )
          .concat(
            savedAssessments.map(item => ({
              name: item.fields.name,
              slug: item.fields.slug,
              customNames: item.fields.customNames,
              objectID: item.sys.id,
              expandedType: 'Assessments',
              timestamp: item.timestamp,
            })),
          )}
      />
    </>
  );
};

export default AccountButton;
