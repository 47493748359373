/**
 *
 * useSiteConfigSelector
 *
 */

import { useSelector } from 'react-redux';
import { makeSelectSiteConfig } from 'containers/Main/selectors';

function useSiteConfigSelector(titles = []) {
  const siteConfigArr = useSelector(makeSelectSiteConfig());

  const relevantSiteConfig = titles.map(title =>
    siteConfigArr.find(item => item.title === title),
  );

  return relevantSiteConfig;
}

export default useSiteConfigSelector;
