import { gql } from '@apollo/client';
import { ReferralClientFragment, ReferralShowFragment } from './fragments';

export default gql`
  ${ReferralShowFragment}
  ${ReferralClientFragment}
  query Referral($locale: String!, $where: ReferralFilter) {
    referralCollection(limit: 100, locale: $locale, where: $where) {
      items {
        sys {
          id
        }
        style
        type
        context
        excludeClientCollection(limit: 30) {
          items {
            ...ReferralClientFields
            ...ReferralClientGroupFields
          }
        }
        clientCollection(limit: 15) {
          items {
            ...ReferralClientFields
            ...ReferralClientGroupFields
          }
        }
        homeHeroImage {
          title
          splashLarge {
            url
          }
          splashMedium {
            url
          }
          splashSmall {
            url
          }
          fontColor
          altText
          isTransparent
        }
        copy
        slug
        showCollection(limit: 55) {
          items {
            ...ReferralAssessmentFields
            ...ReferralAssessmentResultsFields
            ...ReferralLandingFields
          }
        }
        header
        imageCollection(limit: 1) {
          items {
            url
          }
        }
        underAgeMessage {
          json
        }
      }
    }
  }
`;
