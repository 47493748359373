/**
 *
 * AccountSidebarItem
 *
 */

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import _classNames from 'classnames';
import _get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeSelectClientDetails } from 'containers/Main/selectors';
import { getAssessmentName } from 'containers/Assessment/utils';
import { getCustomIcon } from 'components/Resources/utils';
import useSiteCopySelector from 'components/useSiteCopySelector';
import NavBarContext from '../../navBarContext';
import ArrowIcon from './icons/ArrowRight';

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
    position: 'relative',
    marginTop: 16,
    '&:first-child': {
      marginTop: 0,
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 20,
    padding: '10px 15px',
  },
  borderedWrapper: {
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: props => props.theme.borderColor,
  },
  filledWrapper: {
    background: 'linear-gradient(80deg, #F4FAFF 19.13%, #E1F2FE 87.18%)',
  },
  icon: {
    height: 30,
    marginRight: 10,
    maxWidth: 30,
  },
  favoriteIcon: {
    opacity: 0.6,
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gap: 3,
  },
  borderedTitle: {
    color: props => props.theme.linkColor,
  },
  borderedSubtitle: {
    color: props => props.theme.linkColor2,
  },
  filledTitle: {
    color: '#01619B',
  },
  filledSubtitle: {
    color: '#4B4B4B',
  },
  title: {
    fontSize: 16,
    lineHeight: '18px',
    fontFamily: 'Mada',
    fontWeight: 600,
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxHeight: 36 /* Fallback for non-webkit */,
  },
  subtitle: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    lineHeight: '14x',
    fontWeight: '500',
  },
  retakeIcon: {
    marginLeft: 5,
  },
  retakeContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 4,
  },
  retakeLink: {
    textDecoration: 'none',
    position: 'relative',
  },
  retakeWrapper: {
    backgroundColor: '#EE9E00',
    padding: '4px 8px',
    borderRadius: 10,
  },
  retakeLabel: {
    fontFamily: 'Mada',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '1.3',
    color: '#000000',
  },
  arrowIcon: {
    height: 20,
    width: 21,
  },
});

const AccountSidebarItem = ({
  type,
  theme,
  resourceType,
  retake,
  title,
  customNames,
  subtitle,
  onClick,
  onRetakeClick,
  url,
  retakeUrl,
  hideIcon = false,
  iconColor,
}) => {
  const classes = useStyles({ theme });
  const { siteCopy } = useContext(NavBarContext);
  const [resourceItemPageSiteCopy] = useSiteCopySelector([
    'resource-item-page',
  ]);
  const clientDetails = useSelector(makeSelectClientDetails());

  const finalSubtitle =
    type === 'favorite'
      ? _get(resourceItemPageSiteCopy, [
          'pageCopy',
          'algoliaResourceTypeMapping',
          resourceType,
        ])
      : subtitle;

  const isBordered = (type === 'assessment' && !retake) || type === 'favorite';
  const isFilled =
    type === 'courses' ||
    type === 'series' ||
    (type === 'assessment' && retake);

  const leftIcon =
    !hideIcon && getCustomIcon(resourceType, iconColor || theme.linkColor);
  const rightIcon = ArrowIcon(isFilled ? '#01619B' : theme.linkColor);

  const finalTitle = getAssessmentName(
    title,
    _get(customNames, 'config'),
    clientDetails.shortName,
  );

  return (
    <>
      <Link to={url} className={classes.link} onClick={onClick}>
        <Box
          className={_classNames(classes.wrapper, {
            [classes.borderedWrapper]: isBordered,
            [classes.filledWrapper]: isFilled,
          })}
        >
          {leftIcon && <div className={classes.icon}>{leftIcon}</div>}
          <Box className={classes.textWrapper}>
            <Typography
              className={_classNames(classes.title, {
                [classes.borderedTitle]: isBordered,
                [classes.filledTitle]: isFilled,
              })}
            >
              {finalTitle}
            </Typography>
            <Typography
              className={_classNames(classes.subtitle, {
                [classes.borderedSubtitle]: isBordered,
                [classes.filledSubtitle]: isFilled,
              })}
            >
              {finalSubtitle}
            </Typography>
          </Box>
          <div className={classes.arrowIcon}>{rightIcon}</div>
        </Box>
      </Link>
      {retake && (
        <Box className={classes.retakeContainer}>
          <Link
            to={retakeUrl}
            className={classes.retakeLink}
            onClick={onRetakeClick}
          >
            <Box className={classes.retakeWrapper}>
              <Typography className={classes.retakeLabel}>
                {_get(siteCopy, [
                  'pageCopy',
                  'profile-sidebar',
                  'assessmentsRetakeLabel',
                ])}
              </Typography>
            </Box>
          </Link>
        </Box>
      )}
    </>
  );
};

AccountSidebarItem.propTypes = {
  type: PropTypes.string,
  theme: PropTypes.object,
  resourceType: PropTypes.string,
  retake: PropTypes.bool,
  title: PropTypes.string,
  url: PropTypes.string,
  retakeUrl: PropTypes.string,
  subtitle: PropTypes.string,
  iconColor: PropTypes.string,
  onClick: PropTypes.func,
  onRetakeClick: PropTypes.func,
};

export default AccountSidebarItem;
