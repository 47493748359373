/**
 *
 * Main.js
 *
 */

import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import { Helmet } from 'react-helmet-async';
import qs from 'qs';
import { createStructuredSelector } from 'reselect';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _get from 'lodash/get';
import { ReactQueryDevtools } from 'react-query-devtools';
import { emitter } from '@marvelapp/react-ab-test';
import Measure from 'react-measure';
import Hidden from '@material-ui/core/Hidden';
import { incrementSignUpCount } from 'containers/Auth/actions';
import { makeSelectAuth, makeSelectProfile } from 'containers/Auth/selectors';
import { getTakenSeries } from 'containers/Series/actions';
import { getTakenCourses } from 'containers/Course/actions';
import StickyHeader from 'components/StickyHeader';
import NavBar from 'components/NavBarMenu';
import Root from 'components/Root';
import Routes from 'components/Routes';
import withPageBranding from 'components/PageBranding';
import ErrorBoundary from 'components/ErrorBoundary';
import TopBanner from 'components/TopBanner';
import Ribbon from 'components/Ribbon';
import AICCBackHeader from 'components/AICCBackHeader';
import BackHeader from 'components/BackHeader';
import ContentBlock from 'components/ContentBlock';
import { CONTENT_BLOCK_POSITIONS } from 'components/ContentBlock/utils';
import RegionBanner from 'components/RegionBanner';
import useAICC from 'components/Hooks/useAICC';
import useAppcues from 'components/Hooks/useAppcues';
import useAppEmbedMode from 'components/Hooks/useAppEmbedMode';
import useClinicMode from 'components/Hooks/useClinicMode';
import useTruthyState from 'components/Hooks/useTruthyState';
import useFirestoreListener from 'components/Hooks/useFirestoreListener';
import useFreshworksWidget from 'components/Hooks/useFreshworksWidget';
import useHomepagePro from 'components/Hooks/useHomepagePro';
import useIdleTimeout from 'components/Hooks/useIdleTimeout';
import useInsuranceModal from 'components/Hooks/useInsuranceModal';
import usePageTracking from 'components/Hooks/usePageTracking';
import usePersonalEmailModal from 'components/Hooks/usePersonalEmailModal';
import useProfileFilters from 'components/Hooks/useProfileFilters';
import useCustomFavicon from 'components/Hooks/useCustomFavicon';
import NPSSurvey from 'components/NPSSurvey';
import AccountExtraInfoModal from 'components/AccountExtraInfoModal';
import LanguageBanner from 'components/LanguageBanner';
import LeavingAppDialog from 'components/LeavingAppDialog';
import withPageLanguage from 'components/PageLanguage';
import OnboardingQuestionsDialog from 'components/Personalizations/OnboardingQuestionsDialog';
import { LanguageDisclaimer } from 'components/LanguageDisclaimer/LanguageDisclaimer';
import LoadingIndicator from 'components/LoadingIndicator';
import { useCustomTokenSignIn } from 'components/Hooks/useCustomTokenSignIn';
import useSiteConfigSelector from 'components/useSiteConfigSelector';
import InActivityModalConfirmation from 'components/InactivityModalConfirmation';
import useCheckModeParam from 'components/Hooks/useCheckModeParam';
import CustomOnboardingDialog from 'components/Personalizations/CustomOnboardingDialog';
import useCheckViewParam from 'components/Hooks/useCheckViewParam';
import useCheckNumCardsParam from 'components/Hooks/useCheckNumCardsParam';
import useTimeoutLogout from 'components/Hooks/useTimeoutLogout';
import retry from 'utils/retry';
import { isEmbedded, isBareEmbedded, windowScrollTo } from 'utils/embedded';
import { getLocalData, setLocalData, isPublicMode } from 'utils/localDataStore';
import { generateKey, isBot } from 'utils/stringUtils';
import NavBarHeaderWrapper from 'components/NavbarHeaderWrapper';
import useLanguage from 'components/Hooks/useLanguage';
import useInCrisis from 'containers/InCrisis/useInCrisis';
import { getSavedResources } from 'containers/Resources/actions';
import { getTakenPractices } from 'containers/Practices/actions';
import globalSaga from './globalSaga';
import {
  getAudienceTagsRelations,
  getPageIntros,
  getReferrals,
  getSiteCopy,
  getSiteConfig,
  setLanguage,
  showLanguageBanner,
  getLocales,
  getContentBlocks,
  setTextDirection,
  getHomeReferrals,
} from './actions';
import {
  makeSelectClientDetails,
  makeSelectClientDetailsFetching,
  makeSelectClientDetailsLoaded,
  makeSelectLanguage,
  makeSelectShowHomepagePro,
  makeSelectSiteCopy,
  makeSelectSiteConfig,
  makeSelectLocales,
  makeSelectClinicMode,
} from './selectors';
import {
  isNpsSurvey,
  languageCodeMapping,
  navigatorLanguageToContentfulMapping,
} from './utils';

const { Slide, toast } = !isBot() ? require('react-toastify') : {};

const UserConsent = lazy(() => retry(() => import('components/UserConsent')));
const TermsOfUse = lazy(() => retry(() => import('containers/TermsOfUse')));
const PrivacyPolicy = lazy(() =>
  retry(() => import('containers/PrivacyPolicy')),
);
const About = lazy(() => retry(() => import('containers/About')));
const AuthModal = lazy(() => retry(() => import('components/AuthModal')));
const AuthCallToActionModal = lazy(() =>
  retry(() => import('components/AuthCallToActionModal')),
);
const FooterWrapper = lazy(() =>
  retry(() => import('components/FooterWrapper')),
);
// const Sponsors = lazy(() => retry(() => import('components/SponsorFooter')));
// const Footer = lazy(() => retry(() => import('components/Footer')));
const RegionSelector = lazy(() =>
  retry(() => import('components/RegionSelector')),
);

if (toast) {
  // eslint-disable-next-line global-require
  require('react-toastify/dist/ReactToastify.css');
  toast.configure({
    hideProgressBar: true,
    transition: Slide,
  });
}

emitter.defineVariants(
  'hero-variant',
  ['variantA', 'variantB', 'variantC', 'variantD'],
  [25, 25, 25, 25],
);
emitter.defineVariants(
  'assessment-results-lists',
  ['grouped', 'tabbed'],
  [50, 50],
);
emitter.defineVariants(
  'assessment-results-design',
  ['variantA', 'variantB'],
  [50, 50],
);
emitter.calculateActiveVariant('assessment-results-lists');
emitter.calculateActiveVariant('assessment-results-design');

const stateSelector = createStructuredSelector({
  clientDetails: makeSelectClientDetails(),
  clientDetailsFetching: makeSelectClientDetailsFetching(),
  clientDetailsLoaded: makeSelectClientDetailsLoaded(),
  auth: makeSelectAuth(),
  showHomepagePro: makeSelectShowHomepagePro(),
  siteCopy: makeSelectSiteCopy(),
  siteConfig: makeSelectSiteConfig(),
  language: makeSelectLanguage(),
  locales: makeSelectLocales(),
  profile: makeSelectProfile(),
  clinicMode: makeSelectClinicMode(),
});

function Main() {
  const [initialLoaded, setInitialLoaded] = useState(false);
  const [hasMultipleLanguage, setHasMultipleLanguage] = useState(false);
  const [hasChangedRootLang, setHasChangedRootLang] = useState(false);
  const initialRootLang = useRef(document.documentElement.lang);
  const brandName = getLocalData('brand') || 'none';
  const [isBranded, setIsBranded] = useState(brandName !== 'none');
  const { pathname, search } = window.location;
  const isHomePage = pathname === '/';
  const {
    auth,
    clientDetails,
    clientDetailsFetching,
    clientDetailsLoaded,
    clinicMode,
    language,
    locales,
    profile,
    showHomepagePro,
    siteConfig,
    siteCopy,
  } = useSelector(stateSelector);
  const { textDirection } = useLanguage();

  // measure components
  const [measure, setMeasure] = useState(null);

  // check mode param for public device
  useCheckModeParam();

  // check view param
  useCheckViewParam();

  // check num card parm
  useCheckNumCardsParam();

  const bot = isBot();
  const dispatch = useDispatch();
  const clientShortName = _get(clientDetails, 'shortName');
  const isEmbed = isEmbedded();
  const isAdmin = pathname.includes('/admin') && !isEmbed;

  useTimeoutLogout();

  const isBareEmbed = isBareEmbedded();
  const isClientAdmin = pathname.includes('/client-admin');

  const queryParams = qs.parse(search.slice(1));

  useHomepagePro();
  useClinicMode();
  useCustomFavicon();
  useProfileFilters();
  usePersonalEmailModal();
  useInsuranceModal();
  useFirestoreListener();
  useFreshworksWidget();
  const isCustomSignInProcessing = useCustomTokenSignIn();

  const [featuresSiteConfig] = useSiteConfigSelector(['Features']);

  const [openInactivityDialog, setOpenInactivityDialog] = useState(false);

  const handleIdle = () => {
    setOpenInactivityDialog(true);
  };

  const clinicModeInactivityTimeoutInSec = _get(
    featuresSiteConfig,
    'config.clinicModeInactivityTimeoutInSec',
    undefined,
  );

  const isPublicDevice = isPublicMode();

  const enableInactivityTimer =
    clinicMode &&
    pathname.includes('/assessments/') &&
    clientDetailsLoaded &&
    _get(clientDetails, 'metadata.enableClinicModeInactivity', false);

  const { idleTimer } = useIdleTimeout({
    onIdle: handleIdle,
    idleTime: clinicModeInactivityTimeoutInSec,
    enable: enableInactivityTimer || isPublicDevice,
    setOpenInactivityDialog,
  });

  const continueSession = () => {
    setOpenInactivityDialog(false);
    idleTimer.reset();
  };

  // init trackers
  usePageTracking();

  // useAssessmentReminder();

  // scroll top on mount
  useEffect(() => {
    windowScrollTo(0, 0);
  }, []);

  useEffect(() => {
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === 'attributes') {
          const { lang } = mutation.target;
          if (lang !== initialRootLang.current) setHasChangedRootLang(true);
          else setHasChangedRootLang(false);
        }
      });
    });

    observer.observe(document.documentElement, {
      attributes: true,
    });

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    dispatch(setTextDirection(textDirection));
  }, [textDirection, dispatch]);

  useEffect(() => {
    if (
      !initialLoaded &&
      isLoaded(auth) &&
      !_isEmpty(siteCopy) &&
      !_isEmpty(siteConfig) &&
      !_isNil(clinicMode) &&
      clientDetailsLoaded
    ) {
      const loaderEl = document.getElementById('full-page-loader');
      if (loaderEl) loaderEl.parentNode.removeChild(loaderEl);

      setInitialLoaded(true);
    }
  }, [auth, clinicMode, siteCopy, siteConfig, clientDetailsLoaded]);

  useEffect(() => {
    if (auth.isLoaded && !auth.isEmpty) {
      dispatch(incrementSignUpCount());
    }
  }, [auth, dispatch]);

  useEffect(() => {
    /** get locales */
    dispatch(getLocales());

    // set temporary user id in cookies if not set yet, this will be used for recombee interaction when user is not logged in
    let userId = getLocalData('userIdTemp');
    if (_isEmpty(userId)) {
      userId = `anonymous-${generateKey()}`;
      setLocalData('userIdTemp', userId, {
        expires: 365,
      });
      setLocalData('isSubscribed', 'false', {
        expires: 365,
      });
    }

    const regex = new RegExp(
      /\/(apps|articles|blogs|books|lists|faqs|organizations|people|podcasts|programs|topics|videos)\/[\S]+/g,
    );
    if (!regex.test(window.location)) {
      window.prerenderReady = true;
    }
  }, []);

  useEffect(() => {
    if (auth.isLoaded && clientDetailsLoaded) {
      dispatch(getTakenSeries());
      dispatch(getTakenCourses());
      dispatch(getTakenPractices());
      dispatch(getSavedResources());
    }
  }, [auth, clientDetailsLoaded]);

  useEffect(() => {
    if (!_isEmpty(locales)) {
      /** Get Home Banner/Hero content */
      dispatch(getSiteCopy());
      dispatch(getSiteConfig());
      dispatch(getPageIntros());
      dispatch(getHomeReferrals());
      /** Get Audience and Tags Relations */
      dispatch(getAudienceTagsRelations());
      dispatch(getContentBlocks());
    }
  }, [dispatch, locales, language]);

  const wasAssessmentsPage = useTruthyState(() =>
    pathname.includes('/assessments'),
  );

  useEffect(() => {
    if (wasAssessmentsPage && !_isEmpty(locales)) {
      dispatch(getReferrals());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, locales, language, wasAssessmentsPage]);

  useEffect(() => {
    if (
      typeof _get(window, 'xprops.setIframeHeight', '') === 'function' &&
      !_isEmpty(measure) &&
      isEmbed
    ) {
      window.xprops.setIframeHeight(_get(measure, 'dimensions.height', 0));
    }
  }, [measure, isEmbed]);

  useEffect(() => {
    if (
      !_isEmpty(clientShortName) &&
      !_isEmpty(clientDetails) &&
      !clientDetailsFetching
    ) {
      setIsBranded(true);
      setLocalData('brand', clientDetails.shortName);
      const languages = _get(clientDetails, 'languageCollection.items', []);
      const languagesLen = languages.length;
      setHasMultipleLanguage(languagesLen > 1);
      if (languagesLen) {
        if (!initialLoaded) {
          const clientLanguages = _get(
            clientDetails,
            'languageCollection.items',
            [],
          ).map(
            item => languageCodeMapping[(item?.shortcode)] || item?.shortcode,
          );

          const browserLanguage =
            window.navigator.userLanguage || window.navigator.language || '';
          const finalBrowserLanguage = Object.keys(
            navigatorLanguageToContentfulMapping,
          ).includes(browserLanguage)
            ? navigatorLanguageToContentfulMapping[browserLanguage]
            : browserLanguage.split('-')[0];
          if (!isEmbed && _isEmpty(language)) {
            if (
              (languageCodeMapping[finalBrowserLanguage] ||
                finalBrowserLanguage) !== language &&
              clientLanguages.includes(
                languageCodeMapping[finalBrowserLanguage] ||
                  finalBrowserLanguage,
              )
            ) {
              dispatch(setLanguage(finalBrowserLanguage));
            } else {
              dispatch(setLanguage(language || _get(languages, '0.shortcode')));
            }
          }
        } else {
          dispatch(setLanguage(language || _get(languages, '0.shortcode')));
        }
        dispatch(
          showLanguageBanner(
            !hasChangedRootLang &&
              languagesLen > 1 &&
              !showHomepagePro &&
              queryParams.showcorp !== '1',
          ),
        );
      } else {
        dispatch(setLanguage(''));
        dispatch(showLanguageBanner(false));
      }
    }

    if (
      clientDetails !== null &&
      _isEmpty(clientDetails) &&
      !clientDetailsFetching
    ) {
      setIsBranded(false);
      setLocalData('brand', 'none');
      setHasMultipleLanguage(false);
      dispatch(showLanguageBanner(false));
    }
  }, [clientDetails, clientDetailsFetching, hasChangedRootLang]);

  useAICC();
  useAppEmbedMode();
  useAppcues();

  const { showInCrisisInHeader, renderInCrisisButton } = useInCrisis();

  if (isCustomSignInProcessing) return <LoadingIndicator />;

  const notACorporateSite = !showHomepagePro && queryParams.showcorp !== '1';
  const showTopBannerAndRibbon =
    notACorporateSite &&
    isHomePage &&
    _isEmpty(clientDetails) &&
    _isEmpty(clientShortName) &&
    !clientDetailsFetching;
  if (!initialLoaded) return null;

  const useAppEmbedStyle =
    isEmbed || _get(clientDetails, 'metadata.useAppEmbedStyle', false);

  const customFontStylesheetsUrls = _get(
    clientDetails,
    'customFontsCollection.items',
    [],
  ).map(item => item.stylesheet.url);

  return (
    <>
      {customFontStylesheetsUrls.length > 0 && (
        <Helmet>
          {customFontStylesheetsUrls.map(url => (
            <link href={url} key={url} rel="stylesheet" />
          ))}
        </Helmet>
      )}
      <Measure
        bounds
        onResize={contentRect => {
          setMeasure({ dimensions: _get(contentRect, 'bounds') });
        }}
      >
        {({ measureRef }) => (
          <div ref={measureRef}>
            <ErrorBoundary>
              {!isEmbed && (
                <Suspense fallback={<div />}>
                  <UserConsent />
                </Suspense>
              )}
              {!isBareEmbed && (
                <>
                  <BackHeader />
                  <AICCBackHeader />
                </>
              )}
              <StickyHeader>
                {!isBareEmbed && (
                  <>
                    {!isClientAdmin && (
                      <ContentBlock
                        type="common-page"
                        position={CONTENT_BLOCK_POSITIONS.WINDOW_TOP_STICKY}
                        fullWidth
                      />
                    )}

                    {!useAppEmbedStyle &&
                      notACorporateSite &&
                      !isClientAdmin &&
                      !isAdmin && (
                        <>
                          {showTopBannerAndRibbon ? (
                            <>
                              {showInCrisisInHeader && (
                                <Hidden xsDown>
                                  <NavBarHeaderWrapper
                                    rightContent={renderInCrisisButton()}
                                  />
                                </Hidden>
                              )}
                              <Ribbon />
                              <Hidden xsDown>
                                <TopBanner />
                              </Hidden>
                            </>
                          ) : (
                            <Hidden xsDown>
                              {hasMultipleLanguage ? (
                                <LanguageBanner
                                  hasMultipleLanguage={hasMultipleLanguage}
                                />
                              ) : (
                                <RegionBanner />
                              )}
                            </Hidden>
                          )}
                        </>
                      )}
                  </>
                )}
                {!isClientAdmin && <NavBar />}
              </StickyHeader>

              <Root>
                {!isClientAdmin && !isBareEmbed && (
                  <ContentBlock
                    type="common-page"
                    rounded={false}
                    position={CONTENT_BLOCK_POSITIONS.BELOW_MENU_SCROLLS}
                  />
                )}
                <Routes />
              </Root>
              <LeavingAppDialog />
              <Suspense fallback={<div />}>
                <TermsOfUse />
                <PrivacyPolicy />
                {!isBareEmbed && <FooterWrapper isBranded={isBranded} />}

                <LanguageDisclaimer clientDetails={clientDetails} />

                {isNpsSurvey(
                  showHomepagePro,
                  queryParams,
                  isClientAdmin,
                  profile,
                ) && <NPSSurvey />}
                {!bot && (
                  <>
                    <OnboardingQuestionsDialog
                      clientShortName={clientShortName}
                    />
                    <CustomOnboardingDialog />
                    <AuthModal />
                    <AccountExtraInfoModal />
                    <AuthCallToActionModal />
                    {isBranded && !_isEmpty(clientShortName) && (
                      <RegionSelector />
                    )}
                  </>
                )}
                {/* <MyLocation /> */}
                <About />
              </Suspense>
              {openInactivityDialog && (
                <InActivityModalConfirmation clickHandler={continueSession} />
              )}
            </ErrorBoundary>
          </div>
        )}
      </Measure>
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
}

export default compose(
  ...Object.values(globalSaga),
  withPageBranding,
  withPageLanguage,
)(Main);
