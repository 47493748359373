import _get from 'lodash/get';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import qs from 'qs';
import { getUserRatingAndCount } from 'components/UserAndExpertRating/utils';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import Config from 'utils/getEnvConfig';
import { getStore } from 'configureStore';
import { isAIDescriptionEnabled } from 'containers/Main/selectors';
import { getClientArticleSettings } from 'utils/clientSettings';
export * from './utils/filterHandpickedResourceByCutoff';

// TODO: put all utils to separate files

const {
  ALGOLIA: { RESOURCES_AVG_RATED_INDEX, RESOURCES_AVG_RATED_7525_INDEX },
} = Config;

export const isResourceSaved = (savedResources, resource) => {
  const id = _get(resource, 'id') || _get(resource, 'sys.id');
  return (
    id && savedResources && savedResources.map(el => el.objectID).includes(id)
  );
};

export const getLDJson = (type, resource) => {
  let ldJson;
  if (type === 'App') {
    const { name, platform, cost } = resource.fields;
    const { rating = 0, count = 0 } = getUserRatingAndCount(
      resource.fields,
      'app',
    );
    const finalCost = cost ? String(cost) : '0';

    ldJson = {
      '@context': 'https://schema.org',
      '@type': 'SoftwareApplication',
      name,
      operatingSystem: platform ? platform.join() : '',
      applicationCategory: 'HealthApplication',
      aggregateRating: {
        '@type': 'AggregateRating',
        bestRating: '5',
        worstRating: '0',
        ratingValue: String(rating),
        ratingCount: count > 0 ? String(count) : '1',
      },
      offers: {
        '@type': 'Offer',
        price: finalCost,
        priceCurrency: 'USD',
      },
    };
  }
  if (type === 'Program') {
    const { title, description, eLearningCompany, url } = resource.fields;
    const { rating = 0, count = 0 } = getUserRatingAndCount(
      resource.fields,
      'program',
    );
    const finalDescription = documentToPlainTextString(description);
    ldJson = {
      '@context': 'https://schema.org',
      '@type': 'Course',
      name: title,
      description: finalDescription,
      provider: {
        '@type': 'Organization',
        name: eLearningCompany,
        sameAs: url,
      },
    };

    if (rating !== 0 && count !== 0) {
      ldJson.aggregateRating = {
        '@type': 'AggregateRating',
        bestRating: '5',
        worstRating: '0',
        ratingValue: String(rating),
        ratingCount: String(count),
      };
    }
  }
  if (type === 'Book') {
    const { title, person, isbnNumbers } = resource.fields;
    const { rating = 0, count = 0 } = getUserRatingAndCount(
      resource.fields,
      'book',
    );
    const name = person
      ? person
          .map(el => _get(el, 'fields.name'))
          .filter(el => el !== undefined)
          .join(' & ')
      : 'Unknown Author';
    ldJson = {
      '@context': 'https://schema.org',
      '@type': 'Book',
      name: title,
      author: {
        '@type': 'Person',
        name,
      },
      url: window.location.href,
      workExample: [
        {
          '@type': 'Book',
          isbn: isbnNumbers[0],
        },
      ],
    };

    if (rating !== 0 && count !== 0) {
      ldJson.aggregateRating = {
        '@type': 'AggregateRating',
        bestRating: '5',
        worstRating: '0',
        ratingValue: String(rating),
        ratingCount: String(count),
      };
    }
  }
  if (type === 'Video') {
    const {
      title,
      url,
      person,
      description,
      organization,
      imageUrl,
      publishedAt,
    } = resource.fields;
    const finalDescription = documentToPlainTextString(description);
    const { rating = 0, count = 0 } = getUserRatingAndCount(
      resource.fields,
      type.toLowerCase(),
    );
    const hasPerson = !_isEmpty(person);
    const hasOrganization = !_isEmpty(organization);
    const associated = [];
    if (hasPerson) associated.push(...person);
    if (hasOrganization)
      associated.push(
        ...(Array.isArray(organization) ? organization : [organization]),
      );
    const name = associated
      .map(el => _get(el, 'fields.name'))
      .filter(el => el !== undefined)
      .join(' & ');
    ldJson = {
      '@context': 'https://schema.org',
      '@type': 'VideoObject',
      name: title,
      description: finalDescription,
      url: window.location.href,
      creator: {
        '@type': 'Person',
        name,
      },
      embedUrl: url,
      thumbnailUrl: imageUrl,
      uploadDate: publishedAt,
    };

    if (rating !== 0 && count !== 0) {
      ldJson.aggregateRating = {
        '@type': 'AggregateRating',
        bestRating: '5',
        worstRating: '0',
        ratingValue: String(rating),
        ratingCount: String(count),
      };
    }
  }
  if (type === 'Podcast') {
    const {
      title,
      url,
      person,
      description,
      organization,
      publicationDate,
      imageUrl,
    } = resource.fields;
    const finalDescription = documentToPlainTextString(description);
    const { rating = 0, count = 0 } = getUserRatingAndCount(
      resource.fields,
      type.toLowerCase(),
    );
    const hasPerson = !_isEmpty(person);
    const hasOrganization = !_isEmpty(organization);
    const associated = [];
    if (hasPerson) associated.push(...person);
    if (hasOrganization)
      associated.push(
        ...(Array.isArray(organization) ? organization : [organization]),
      );
    const name = associated
      .map(el => _get(el, 'fields.name'))
      .filter(el => el !== undefined)
      .join(' & ');
    ldJson = {
      '@context': 'https://schema.org',
      '@type': 'AudioObject',
      name: title,
      url: window.location.href,
      description: finalDescription,
      uploadDate: publicationDate,
      creator: {
        '@type': 'Person',
        name,
      },
      embedUrl: url,
      thumbnailUrl: imageUrl,
    };

    if (rating !== 0 && count !== 0) {
      ldJson.aggregateRating = {
        '@type': 'AggregateRating',
        bestRating: '5',
        worstRating: '0',
        ratingValue: String(rating),
        ratingCount: String(count),
      };
    }
  }
  return ldJson;
};

export const getScoreText = (type, opened) => {
  if (type === 'book' || type === 'activity') {
    if (opened) return 'YOU READ THIS! RATE IT.';
    return "I'VE READ THIS";
  }
  if (type === 'application') {
    if (opened) return 'YOU USED THIS! RATE IT.';
    return "I'VE USED THIS";
  }
  if (type === 'podcast') {
    if (opened) return 'YOU LISTENED TO THIS! RATE IT.';
    return "I'VE LISTENED TO THIS";
  }
  if (type === 'events') {
    if (opened) return 'YOU WENT TO THIS! RATE IT.';
    return 'I WENT TO THIS';
  }
  if (type === 'video') {
    if (opened) return 'YOU WATCHED THIS! RATE IT.';
    return "I'VE WATCHED THIS";
  }

  return null;
};

export const sortByTopicsRelevance = (resources, topics) => {
  const topicsMap = {};
  topics.forEach((el, index) => {
    topicsMap[el] = 10 ** (topics.length - index - 1);
  });

  const sortedResources = resources
    .map(el => {
      const multiplier = el.allTopics.reduce((accumulator, currentValue) => {
        if (topicsMap[currentValue.title] != null)
          return accumulator + topicsMap[currentValue.title];
        return accumulator;
      }, 1);
      const finalRanking = el.avgRating * multiplier;
      return {
        ...el,
        finalRanking,
      };
    })
    .sort((a, b) => b.finalRanking - a.finalRanking);
  return sortedResources;
};

export const getFeedbackDBCollectionNameByType = type => {
  let dbCollectionName = 'user_feedback_resources_public';
  if (type === 'blog') {
    dbCollectionName = 'user_feedback_blogs';
  } else if (type === 'topic') {
    dbCollectionName = 'user_feedback_topics';
  } else if (type === 'assessment') {
    dbCollectionName = 'user_feedback_assessments';
  }

  return dbCollectionName;
};

export const getResourceAudience = resource => {
  const audienceType = _get(
    resource,
    ['items', 0, 'fields', 'audienceType'],
    null,
  );
  if (!audienceType) return [];

  return audienceType
    .map(audience => {
      const audienceReviewStatus = _get(
        audience,
        ['fields', 'reviewStatus'],
        null,
      );
      if (audienceReviewStatus === 'Accepted') {
        const audienceName = _get(audience, ['fields', 'name']);

        return audienceName;
      }

      return null;
    })
    .filter(audience => audience !== null);
};

export const getPopulateReview = () =>
  window.location.pathname.includes('/apps/') ||
  window.location.pathname.includes('/articles/') ||
  window.location.pathname.includes('/blogs/') ||
  window.location.pathname.includes('/books/') ||
  window.location.pathname.includes('/programs/') ||
  window.location.pathname.includes('/podcasts/') ||
  window.location.pathname.includes('/videos/') ||
  window.location.pathname.includes('/insights/');

export const getResourcesIndex = weightings => {
  if (weightings === '75-25') return RESOURCES_AVG_RATED_7525_INDEX;

  return RESOURCES_AVG_RATED_INDEX;
};

const createURL = filters => `?${qs.stringify(filters)}`;

export const searchStateToUrl = (location, filters) =>
  filters ? `${location.pathname}${createURL(filters)}` : '';

const maybeReplaceTopicChar = topic =>
  topic.includes('--') ? topic.replace(/--/g, '&') : topic;

const getMutatedAllTopics = allTopics => {
  let mutatedAllTopics;
  if (typeof allTopics === 'string') {
    mutatedAllTopics = maybeReplaceTopicChar(allTopics);
  } else {
    mutatedAllTopics = allTopics.map(topic => maybeReplaceTopicChar(topic));
  }
  return mutatedAllTopics;
};

export const urlToSearchState = location => {
  const state = qs.parse(location.search.slice(1), { arrayLimit: 200 });
  if (!state.types) state.types = [];
  else
    state.types = typeof state.types !== 'string' ? state.types : [state.types];

  if (!state.topics) state.topics = [];
  else {
    const mutatedTopics = getMutatedAllTopics(state.topics);
    state.topics =
      typeof mutatedTopics !== 'string' ? mutatedTopics : [mutatedTopics];
  }

  if (!state.query) state.query = '';

  if (!state.cost) state.cost = [];
  else state.cost = typeof state.cost !== 'string' ? state.cost : [state.cost];

  if (!state.length) state.length = [];
  else
    state.length =
      typeof state.length !== 'string' ? state.length : [state.length];

  if (!state.focus) state.focus = [];
  else
    state.focus = typeof state.focus !== 'string' ? state.focus : [state.focus];

  if (!state.audience) state.audience = [];
  else
    state.audience =
      typeof state.audience !== 'string' ? state.audience : [state.audience];

  if (!state.language) state.language = [];
  else
    state.language =
      typeof state.language !== 'string' ? state.language : [state.language];

  // if (!state.geo) state.geo = '';
  if (!state.presentation) state.presentation = 'separate';

  return state;
};

export const filterClientExclusiveResources = ({
  resources,
  clientShortName,
  clientGroupShortName,
}) => {
  if (!resources) {
    return [];
  }

  return resources.filter(field => {
    const isContentful = _has(field, 'fields');
    const isSearchIndex = _has(field, 'clients');
    let clientsShortName = [];
    let clientsExcludeShortName = [];
    if (isContentful) {
      clientsShortName = _get(field, 'fields.client', [])
        .concat(_get(field, 'fields.clientInclude', []))
        .map(clientFields => _get(clientFields, 'fields.shortName'));
      clientsExcludeShortName = _get(field, 'fields.clientExclude', []).map(
        clientFields => _get(clientFields, 'fields.shortName'),
      );
    } else if (isSearchIndex) {
      clientsShortName = _get(field, 'clients', []).filter(
        name => name !== 'none',
      );
      clientsExcludeShortName = _get(field, 'clientsExclude', []);
    } else {
      clientsShortName = _get(field, 'client', [])
        .map(clientFields => _get(clientFields, 'shortName'))
        .filter(name => name !== 'none');
      clientsExcludeShortName = _get(field, 'clientExclude', []).map(
        clientFields => _get(clientFields, 'shortName'),
      );
    }
    return !clientShortName
      ? !clientsShortName.length
      : !clientsExcludeShortName.includes(clientShortName) &&
          (!clientsShortName.length ||
            clientsShortName.includes(clientShortName) ||
            clientsShortName.includes(clientGroupShortName));
  });
};

export const parseAvailableInFilters = ({ availableInFacetHits }) => {
  const finalAvailableInHits = availableInFacetHits.map(hit => {
    if (hit.value === 'Habla Español')
      return {
        ...hit,
        id: '01uO1Tey6SU3SALlMA8mbs',
      };
    return hit;
  });

  return finalAvailableInHits;
};

export const getClientAudienceFilters = (profileFilters, field) => {
  let clientAudienceQuery = '';
  if (!_isEmpty(_get(profileFilters, 'audiences'))) {
    let hasExclusiveAudience = false;
    const clientAudiences = profileFilters.audiences.filter(
      item => item.clientAudience,
    );
    const exclusiveClientAudiences = clientAudiences
      .filter(item => item.exclusive)
      .map(item => item.id);

    if (!_isEmpty(exclusiveClientAudiences)) {
      hasExclusiveAudience = true;
      clientAudienceQuery += ' AND (';
      exclusiveClientAudiences.forEach((id, index) => {
        if (index > 0) {
          clientAudienceQuery += ` OR ${field}:"${id}"`;
        } else {
          clientAudienceQuery += `${field}:"${id}"`;
        }
      });
      clientAudienceQuery += ')';
    }

    if (!hasExclusiveAudience && !_isEmpty(_get(clientAudiences, 'exclude'))) {
      _get(clientAudiences, 'exclude', []).forEach(id => {
        clientAudienceQuery += ` AND NOT ${field}:"${id}"`;
      });
    }
  }
  return clientAudienceQuery;
};

export const injectTimestampIntoFavoritedItem = (favorites, items) => {
  const mappedItems = items.map(item => {
    const favorite = favorites.find(
      fav => fav.topicID === item.sys.id || fav.assessmentID === item.sys.id,
    );

    if (favorite) {
      return {
        ...item,
        timestamp: favorite.timestamp,
      };
    }

    return item;
  });

  return mappedItems;
};

export const mapResourceWithAIFields = item => {
  const hasAITag = item.metadata?.tags.some(
    t => t.sys.id === 'aiCustomDescription',
  );
  if (!hasAITag && !isAIDescriptionEnabled(getStore().getState())) {
    return item;
  }

  // move fields might be added in future
  const mapFieldToAIField = {
    description: 'aiSummary',
  };
  const aiFields = Object.keys(mapFieldToAIField).reduce((res, key) => {
    const aiField = mapFieldToAIField[key];
    const value = item.fields[aiField] ?? item.fields[key];
    res[key] = value;
    return res;
  }, {});

  return {
    ...item,
    fields: { ...item.fields, ...aiFields },
  };
};

export const getArticlesMinimumWordCount = (featuresConfig, clientDetails) => {
  const {
    articlesMinimumWordCount: clientArticlesMinimumWordCount,
  } = getClientArticleSettings(clientDetails);
  const articlesMinimumWordCount =
    clientArticlesMinimumWordCount ||
    _get(featuresConfig, 'config.articlesMinimumWordCount', 400);
  return articlesMinimumWordCount;
};
