import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import {
  getClientExcludedResourceTypesMapping,
  getFormattedType,
} from 'components/ResourceItemSelector/utils';
import { makeSelectClientDetails } from 'containers/Main/selectors';

const useClientExcludedResourceType = () => {
  const clientDetails = useSelector(makeSelectClientDetails());
  const clientExcludedResourceTypes =
    _get(clientDetails, 'excludeResourceTypes') || [];
  const clientExcludedTopics =
    clientDetails?.excludeTopicCollection?.items?.map(topic => topic?.slug) ||
    [];

  const clientExcludedAssessments =
    clientDetails?.excludeAssessmentCollection?.items?.map(
      assessment => assessment?.slug,
    ) || [];

  const mapResourceTypes = mapFunc =>
    clientExcludedResourceTypes.map(mapFunc).filter(Boolean);

  const clientExcludedResourceTypesToken = mapResourceTypes(
    getClientExcludedResourceTypesMapping,
  );
  const clientExcludedResourceTypesFromAlgoliaToken = mapResourceTypes(
    getFormattedType,
  );

  const isResourceTypeExcluded = resourceType =>
    clientExcludedResourceTypesToken.includes(resourceType);
  const isAlgoliaResourceTypeExcluded = resourceType =>
    clientExcludedResourceTypesFromAlgoliaToken.includes(resourceType);

  const isTopicExcluded = topicSlug => clientExcludedTopics.includes(topicSlug);
  const isAssessmentExcluded = assessmentSlug =>
    clientExcludedAssessments.includes(assessmentSlug);

  return {
    isResourceTypeExcluded,
    isAlgoliaResourceTypeExcluded,
    clientExcludedResourceTypes,
    isTopicExcluded,
    isAssessmentExcluded,
  };
};

export default useClientExcludedResourceType;
