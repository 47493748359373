import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import _has from 'lodash/has';
import { getAssessmentName } from 'containers/Assessment/utils';
import { getColorsConfig } from 'containers/Assessments/utils';
import { makeSelectClientDetails } from 'containers/Main/selectors';
import useSiteCopySelector from 'components/useSiteCopySelector';
import { getImageFile } from 'utils/stringUtils';

const useStyles = makeStyles({
  container: {
    width: '100%',
    padding: '10px 20px',
    display: 'flex',
    gap: '10px',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    fontFamily: 'Mada',
    alignItems: 'center',
    cursor: 'pointer',
  },
  imageContainer: {
    borderRadius: '10px',
    height: '70px',
    width: '70px',
    overflow: 'hidden',
  },
  image: {
    height: '70px',
    width: '70px',
    objectFit: 'cover',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    flex: 1,
  },
  cardTag: {
    display: 'flex',
    gap: '7px',
    alignItems: 'center',
  },
  cardIcon: {},
  assessmentIcon: {
    height: '12px',
    width: '12px',
  },
  cardTitle: {
    margin: 0,
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '18px',
    paddingTop: '2px',
  },
  title: {
    margin: 0,
    fontFamily: 'Poppins',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '17px',
  },
  subTitle: {
    margin: 0,
  },
});

export const AssessmentCard = ({ item }) => {
  const classes = useStyles();
  const { time, slug, name = '', customNames } = item;
  const [
    resourceItemPageSiteCopy,
    allAssessmentsSiteCopy,
  ] = useSiteCopySelector(['resource-item-page', 'all-assessments']);
  const clientDetails = useSelector(makeSelectClientDetails());

  const { checkSvg } = getColorsConfig(item);

  const durationPageCopy = _get(
    allAssessmentsSiteCopy,
    'pageCopy.takesOnlyLabel',
    'Take assessment for only #time minute(s)',
  );
  const duration = durationPageCopy.replace('#time', time);

  const imageUrl = getImageFile(item);
  const customNamesConfig = _has(customNames, 'config')
    ? _get(customNames, 'config', [])
    : customNames;
  const finalName = getAssessmentName(
    name,
    customNamesConfig,
    clientDetails.shortName,
  );

  return (
    <Link to={`/assessments/${slug}`} className={classes.container}>
      <div className={classes.imageContainer}>
        <img src={imageUrl} alt={finalName} className={classes.image} />
      </div>
      <div className={classes.content}>
        <div className={classes.cardTag}>
          <div className={classes.cardIcon}>
            <img src={checkSvg} alt="" className={classes.assessmentIcon} />
          </div>
          <p className={classes.cardTitle}>
            {_get(
              resourceItemPageSiteCopy,
              'pageCopy.resourceCardTypeMapping.Assessment',
            )}
          </p>
        </div>
        <p className={classes.title}>{finalName}</p>
        <p className={classes.subTitle}>{duration}</p>
      </div>
    </Link>
  );
};
