export const RESOURCE_PAGE_LAYOUT_RESPONSIVE = {
  xl: {
    padding: 56,
    asideWidth: 398,
  },
  lg: {
    padding: 56,
    asideWidth: 398,
  },
  md: {
    padding: 40,
    asideWidth: 300,
  },
  sm: {
    padding: 0,
    asideWidth: 0,
  },
  xs: {
    padding: 0,
    asideWidth: 0,
  },
};

export const getListPageCardGridSize = (isWideSeriesCard = false) => ({
  xs: isWideSeriesCard ? 12 : 6,
  sm: isWideSeriesCard ? 8 : 4,
  md: isWideSeriesCard ? 6 : 3,
  lg: isWideSeriesCard ? 6 : 3,
  xl: isWideSeriesCard ? 4 : 2,
});
