/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import history from 'utils/history';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';

import accountReducer from 'containers/Account/reducer';
import authReducer from 'containers/Auth/reducer';
import assessmentsReducer from 'containers/Assessments/reducer';
import topicsReducer from 'containers/Topics/reducer';
import assessmentReducer from 'containers/Assessment/reducer';
import sourcesReducer from 'containers/Sources/reducer';
import resourcesReducer from 'containers/Resources/reducer';
import mainReducer from 'containers/Main/reducer';
import clientLandingReducer from 'containers/ClientLanding/reducer';
import homePageReducer from 'containers/HomePage/reducer';
import favoritesReducer from 'containers/Favorites/reducer';
import seriesReducer from 'containers/Series/reducer';
import coursesReducer from 'containers/Course/reducer';
import practicesReducer from 'containers/Practices/reducer';
/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    main: mainReducer,
    homepage: homePageReducer,
    assessments: assessmentsReducer,
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    auth: authReducer,
    account: accountReducer,
    topics: topicsReducer,
    assessment: assessmentReducer,
    sources: sourcesReducer,
    resources: resourcesReducer,
    clientLanding: clientLandingReducer,
    favorites: favoritesReducer,
    series: seriesReducer,
    courses: coursesReducer,
    practices: practicesReducer,
    router: connectRouter(history),
    ...injectedReducers,
  });

  return rootReducer;
}
