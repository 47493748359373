import { take, takeLatest, call, put, select } from 'redux-saga/effects';
// import { push } from 'connected-react-router';
import AdminClient from 'utils/adminService';
import AlgoliaClient from 'utils/algoliaService';
import client from 'utils/contentfulService';
import { getLocalData } from 'utils/localDataStore';
import { getSubdomain, isBot } from 'utils/stringUtils';
import { getFirebase } from 'react-redux-firebase';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _uniqBy from 'lodash/uniqBy';
import _isNil from 'lodash/isNil';
import { makeSelectTakenInSession } from 'containers/Assessment/selectors';
import { storeItemForSignIn, setAuthModalState } from 'containers/Auth/actions';
import {
  makeSelectAnonymousId,
  makeSelectProfileFilters,
} from 'containers/Auth/selectors';
import { GET_CLIENT_DETAILS_SUCCESS } from 'containers/Main/constants';
import { makeSelectClientDetails } from 'containers/Main/selectors';
import {
  getContentfulRatingQuery,
  getAlgoliaRatingQuery,
  getAlgoliaTopicsQuery,
  getAlgoliaFilters,
  getContentfulLocaleFilter,
  getAlgoliaClientQuery,
} from 'containers/Main/utils';
import {
  filterClientExclusiveResources,
  getResourcesIndex,
  injectTimestampIntoFavoritedItem,
} from 'containers/Resources/utils';

import {
  GET_ASSESSMENTS,
  GET_TAKEN_ASSESSMENTS,
  GET_SAVED_ASSESSMENTS,
  GET_RECOMMENDED_RESOURCES,
  SAVE_ASSESSMENT,
  GET_ALL_ASSESSMENTS_RESULTS,
} from './constants';
import {
  getAssessmentsSuccess,
  getAssessmentsFail,
  getTakenAssessmentsResult,
  getSavedAssessments,
  getSavedAssessmentsResult,
  saveAssessmentResult,
  processing,
  sectionProcessing,
  getRecommendedResourcesResult,
  getAllAssessmentsResultsResult,
} from './actions';
import { filterAssessmentByRequiredTags } from './utils';

function* getAssessmentsSaga({ payload }) {
  const { queryOpt = {}, type = '' } = payload || {};
  try {
    // add section processing to properly indicate which part of app is fetching new data since they are using the same actions/saga
    if (type === 'menu') {
      yield put(sectionProcessing(true));
    } else {
      yield put(processing(true));
    }

    const clientShortName = getLocalData('brand');
    const hasClientShortName =
      !_isNil(getSubdomain()) ||
      (!_isEmpty(clientShortName) && clientShortName !== 'none');

    let clientDetails;
    if (hasClientShortName) {
      clientDetails = yield select(makeSelectClientDetails());
      if (_isEmpty(clientDetails)) {
        yield take(GET_CLIENT_DETAILS_SUCCESS);
        clientDetails = yield select(makeSelectClientDetails());
      }
    }
    const contentfulRatingQuery = yield call(getContentfulRatingQuery);
    const localeFilters = yield call(getContentfulLocaleFilter);
    const { limit, ...otherOpt } = queryOpt;
    const finalLimit = limit || 1000;
    const response = yield call(() =>
      client.getEntries({
        content_type: 'assessments',
        'fields.reviewStatus': 'Accepted',
        order: '-fields.userRating,fields.name',
        limit: finalLimit,
        ...otherOpt,
        ...contentfulRatingQuery,
        ...localeFilters,
      }),
    );
    const filteredItems = filterClientExclusiveResources({
      resources: yield call(filterAssessmentByRequiredTags, response.items),
      clientShortName: _get(clientDetails, 'shortName'),
      clientGroupShortName: _get(clientDetails, 'clientGroup.shortName'),
    });
    yield put(
      getAssessmentsSuccess({ data: filteredItems.slice(0, limit), type }),
    );
  } catch (error) {
    yield put(getAssessmentsFail(error));
  } finally {
    if (type === 'menu') {
      // yield put(sectionProcessing(false));
    } else {
      yield put(processing(false));
    }
  }
}

function* getTakenAssessmentsSaga({ payload }) {
  const { type = '' } = payload || {};
  try {
    if (type === 'menu') {
      yield put(sectionProcessing(true));
    } else {
      yield put(processing(true));
    }

    let userID;
    let collection;
    let isSignedIn = false;
    try {
      userID = yield getFirebase().auth().currentUser.uid;
      collection = 'user_resources';
      isSignedIn = true;
    } catch (e) {
      const sessionAnonymousId = yield select(makeSelectAnonymousId());
      userID = getLocalData('userIdTemp') || sessionAnonymousId;
      collection = 'anonymous_assessments';
    }

    const localeFilters = yield call(getContentfulLocaleFilter);
    const takenInSession = yield select(makeSelectTakenInSession());

    const takenAssessmentsDocs = yield getFirebase()
      .firestore()
      .collection(collection)
      .doc(userID)
      .collection('taken_assessments')
      .orderBy('timestamp', 'desc')
      .get();
    let takenAssessments = _uniqBy(
      takenAssessmentsDocs.docs.map(doc => ({ docId: doc.id, ...doc.data() })),
      'assessment.slug',
    );

    if (takenAssessments.length > 0) {
      const ids = takenAssessments.map(el => el.assessment.assessmentID);
      const includeQuery = type !== 'menu' ? { include: 2 } : {};
      const response = yield call(() =>
        client.getEntries({
          content_type: 'assessments',
          'fields.reviewStatus': 'Accepted',
          order: '-fields.userRating,fields.name',
          limit: 1000,
          ...includeQuery,
          ...localeFilters,
        }),
      );
      const filteredItems = yield call(
        filterAssessmentByRequiredTags,
        response.items,
      );
      const data = filteredItems.filter(el => ids.includes(el.sys.id));
      const validTakenAssessmentsIds = data.map(el => el.sys.id);
      takenAssessments = takenAssessments.filter(
        el =>
          validTakenAssessmentsIds.includes(el.assessment.assessmentID) &&
          (isSignedIn ||
            (!isSignedIn && takenInSession.includes(el.assessment.slug))),
      );
      takenAssessments = takenAssessments.map(el => {
        const assessmentObj = response.items.filter(
          item => item.sys.id === el.assessment.assessmentID,
        )[0];
        return {
          ...el,
          fields: {
            slug: _get(assessmentObj, 'fields.slug'),
            name: _get(assessmentObj, 'fields.name'),
            color: _get(assessmentObj, 'fields.color'),
            image: _get(assessmentObj, 'fields.image'),
            customNames: _get(assessmentObj, 'fields.customNames'),
          },
        };
      });
      yield put(getTakenAssessmentsResult(takenAssessments));
    } else {
      yield put(getTakenAssessmentsResult([]));
    }
  } catch (error) {
    yield put(getTakenAssessmentsResult([]));
  } finally {
    if (type === 'menu') {
      yield put(sectionProcessing(false));
    } else {
      yield put(processing(false));
    }
  }
}

function* getSavedAssessmentsSaga() {
  try {
    yield put(processing(true));
    const localeFilters = yield call(getContentfulLocaleFilter);
    const userID = yield getFirebase().auth().currentUser.uid;
    const savedAssessmentsDocs = yield getFirebase()
      .firestore()
      .collection('user_resources')
      .doc(userID)
      .collection('saved_assessments')
      .get();
    const savedAssessments = savedAssessmentsDocs.docs.map(doc => doc.data());
    const savedAssessmentsIDs = savedAssessments.map(item => item.assessmentID);
    if (savedAssessmentsIDs.length > 0) {
      const idString = savedAssessmentsIDs.join();
      const response = yield call(() =>
        client.getEntries({
          content_type: 'assessments',
          'sys.id[in]': idString,
          'fields.reviewStatus': 'Accepted',
          order: '-fields.userRating,fields.name',
          ...localeFilters,
        }),
      );
      const filteredItems = yield call(
        filterAssessmentByRequiredTags,
        response.items,
      );
      const finalItems = injectTimestampIntoFavoritedItem(
        savedAssessments,
        filteredItems,
      );
      yield put(getSavedAssessmentsResult(finalItems));
    } else {
      yield put(getSavedAssessmentsResult([]));
    }
  } catch (error) {
    yield put(getSavedAssessmentsResult([]));
  } finally {
    yield put(processing(false));
  }
}

function* getRecommendedResourcesSaga() {
  try {
    yield put(processing(true));
    if (isBot()) throw new Error('Do not call Algolia for Bot requests');
    const ratingCutOffQuery = yield call(getAlgoliaRatingQuery);
    const topicsQuery = yield call(getAlgoliaTopicsQuery, 'filterTopics');
    const typeFilters = 'AND type:Assessments';

    const profileFilters = yield select(makeSelectProfileFilters());

    const clientDetails = yield select(makeSelectClientDetails());
    const excludedAssessments = _get(
      clientDetails,
      'excludeAssessmentCollection.items',
    );
    const {
      algoliaAssessmentsQuery = '',
      algoliaRequiredTagsQuery,
      algoliaStateQuery,
    } = getAlgoliaFilters({
      excludedAssessments,
      profileFilters,
    });
    const resourcesWeightings = _get(
      clientDetails,
      'metadata.algoliaResourcesWeight',
    );

    const AlgoliaIndex = AlgoliaClient.initIndex(
      getResourcesIndex(resourcesWeightings),
    );

    const filterQuery = ` AND ${getAlgoliaClientQuery(clientDetails)}`;
    const queryParams = {
      hitsPerPage: 100,
      filters: `reviewStatus:'Accepted' ${filterQuery} ${ratingCutOffQuery} ${topicsQuery} ${typeFilters} ${algoliaAssessmentsQuery} ${algoliaRequiredTagsQuery} ${algoliaStateQuery}`,
    };
    const response = yield call(() => AlgoliaIndex.search('', queryParams));
    yield put(getRecommendedResourcesResult(response.hits));
  } catch (error) {
    yield put(getRecommendedResourcesResult([]));
  } finally {
    yield put(processing(false));
  }
}

function* saveAssessmentSaga({ payload }) {
  try {
    yield put(processing(true));
    yield put(storeItemForSignIn({}));
    let userID;
    try {
      userID = yield getFirebase().auth().currentUser.uid;
    } catch (error) {
      // const {
      //   location: { pathname },
      // } = payload;
      yield put(storeItemForSignIn({ type: 'assessment', payload }));
      yield put(saveAssessmentResult({ error: true }));
      yield put(
        setAuthModalState({
          show: true,
          type: 'favorite',
        }),
      );
      // yield put(push(`/login?redirect=${pathname}`));
    }
    if (userID != null) {
      const { save, assessment } = payload;
      const userAssessmentDoc = yield getFirebase()
        .firestore()
        .collection('user_resources')
        .doc(userID)
        .collection('saved_assessments')
        .doc(assessment.assessmentID);
      if (save) {
        if (!userAssessmentDoc.exists) {
          yield userAssessmentDoc.set({ ...assessment, timestamp: Date.now() });
          yield put(
            saveAssessmentResult({
              added: true,
              error: false,
            }),
          );
        } else {
          yield put(
            saveAssessmentResult({
              added: false,
              error: false,
            }),
          );
        }
      } else {
        yield userAssessmentDoc.delete();
        yield put(
          saveAssessmentResult({
            added: false,
            error: false,
          }),
        );
      }
    }
  } catch (error) {
    yield put(saveAssessmentResult({ error: true }));
  } finally {
    yield put(getSavedAssessments());
  }
}

function* getAllAssessmentsResultsSaga({ payload }) {
  try {
    yield put(processing(true));
    const { start, end } = payload;
    const response = yield AdminClient.getAssessmentResults(start, end);
    yield put(getAllAssessmentsResultsResult(response.data.data));
  } catch (error) {
    yield put(getAllAssessmentsResultsResult([]));
  } finally {
    yield put(getSavedAssessments());
  }
}

// Individual exports for testing
export default function* defaultSaga() {
  yield takeLatest(GET_ASSESSMENTS, getAssessmentsSaga);
  yield takeLatest(GET_TAKEN_ASSESSMENTS, getTakenAssessmentsSaga);
  yield takeLatest(GET_SAVED_ASSESSMENTS, getSavedAssessmentsSaga);
  yield takeLatest(GET_RECOMMENDED_RESOURCES, getRecommendedResourcesSaga);
  yield takeLatest(SAVE_ASSESSMENT, saveAssessmentSaga);
  yield takeLatest(GET_ALL_ASSESSMENTS_RESULTS, getAllAssessmentsResultsSaga);
}
