const pathMapping = {
  'Online Programs': 'programs',
  FAQ: 'faqs',
  News: 'articles',
};

export const getFavoriteResourceUrl = item => {
  if (item.expandedType === 'Practice') {
    return `/learning-lab?slug=${item.slug}#practices`;
  }

  const path =
    pathMapping[item.expandedType] ?? item.expandedType.toLowerCase();
  return `/${path}/${item.slug}`;
};
