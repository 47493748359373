/**
 *
 * LinkItem
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import { setLeavingModal } from 'containers/Main/actions';
import { makeSelectClientDetails } from 'containers/Main/selectors';
import { getAccentColor } from 'containers/Main/utils';
import { getEmbedlyImageUrl } from 'utils/embedlyUtils';
import useAlgoliaLocale from 'components/useAlgoliaLocale';
import TooltipBadge from 'components/TooltipBadge';
import { getAlgoliaLocalizedField } from 'utils/localeUtils';
import { fallbackImage } from 'utils/stringUtils';
import { getClientLinkUrl } from 'utils/analyzeClientResource';
import { getExclusiveBadge } from '../utils';

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
    position: 'relative',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  image: {
    height: 77,
    width: 77,
    marginRight: 12,
    objectFit: props => (props.displayNewLinkCard ? 'cover' : 'contain'),
  },
  type: {
    fontSize: 11,
    lineHeight: '14px',
    display: '-webkit-box',
    '-webkit-line-clamp': '1',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: 14 /* Fallback for non-webkit */,
  },
  title: {
    fontSize: 14,
    lineHeight: '18px',
    fontFamily: 'MadaBold',
    fontWeight: 700,
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxHeight: 36 /* Fallback for non-webkit */,
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 89px)',
    textAlign: 'left',
  },
  userRatingLabel: {
    fontSize: 12,
    lineHeight: '15px',
  },
  placeholder: {
    width: '100%',
    height: 27,
  },
  imageWrapper: {
    position: 'relative',
    height: '100%',
    backgroundColor: '#FFFFFF',
  },
});

const LinkItem = ({ data, onClick, variantELocale }) => {
  const dispatch = useDispatch();
  const clientDetails = useSelector(makeSelectClientDetails());

  const locale = useAlgoliaLocale();
  const displayNewLinkCard = _get(
    clientDetails,
    'metadata.displayNewLinkCard',
    false,
  );
  const classes = useStyles({ displayNewLinkCard });
  if (_isEmpty(data)) return null;

  const { type, nameTitle, imageUrl } = data;
  const url = getClientLinkUrl(data, clientDetails);
  const {
    badge,
    badgeTitle,
    showClientBadge,
    hasCustomBadge,
  } = getExclusiveBadge(data, clientDetails);

  const handleClick = () => {
    onClick({
      type,
      name: nameTitle,
      entryId: data.objectID,
    });
    dispatch(
      setLeavingModal({
        visible: true,
        title: getAlgoliaLocalizedField(
          data,
          'nameTitle',
          variantELocale || locale,
        ),
        url: `${url}?lang=${variantELocale || locale}`,
        organization: nameTitle,
        resourceType: type,
      }),
    );
  };

  const accentColor = getAccentColor(clientDetails, '01619B');
  const cardRootColor = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: `#${accentColor}`,
    opacity: 0.8,
    mixBlendMode: 'screen',
  };

  return (
    <ButtonBase disableRipple onClick={handleClick}>
      <div className={classes.wrapper}>
        <div className={classes.imageWrapper}>
          <img
            src={getEmbedlyImageUrl(imageUrl) || fallbackImage}
            alt={`${nameTitle} Resource`}
            className={classes.image}
            onError={e => {
              e.target.src = fallbackImage;
            }}
          />
          {displayNewLinkCard ? <div style={cardRootColor} /> : null}
          {showClientBadge && !!badge && (
            <TooltipBadge
              title={badgeTitle}
              image={badge}
              size={18}
              customBadge={hasCustomBadge}
              position="topRight"
            />
          )}
        </div>
        <div className={classes.textWrapper}>
          <Typography color="textPrimary" className={classes.type}>
            {_get(
              clientDetails,
              'metadata.linksTypeLabel',
              getAlgoliaLocalizedField(data, 'type', variantELocale || locale),
            ).toUpperCase()}
          </Typography>
          <Typography color="textPrimary" className={classes.title}>
            {getAlgoliaLocalizedField(
              data,
              'nameTitle',
              variantELocale || locale,
            )}
          </Typography>
          <div className={classes.placeholder} />
        </div>
      </div>
    </ButtonBase>
  );
};

LinkItem.propTypes = {
  data: PropTypes.object,
  onClick: PropTypes.func,
};

export default LinkItem;
