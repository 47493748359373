import AlgoliaClient from 'utils/algoliaService';
import _flatten from 'lodash/flatten';
import _forEach from 'lodash/forEach';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import {
  getResourcesIndex,
  getArticlesMinimumWordCount,
} from 'containers/Resources/utils';
import {
  getAlgoliaClientQuery,
  getAlgoliaFilters,
} from 'containers/Main/utils';
import { getClientArticleSettings } from 'utils/clientSettings';
import { getAlgoliaRatingQuery, getAlgoliaTopicsQuery } from './api-helper';

let algoliaIndex;

async function getAlgoliaData(
  limit,
  typeFilters,
  clientDetails,
  clientLandingInfo,
  audienceTagsRelations,
  profileFilters,
  key,
  featuresConfig,
) {
  const excludedAssessments = _get(
    clientDetails,
    'excludeAssessmentCollection.items',
  );
  const ratingCutOffQuery = getAlgoliaRatingQuery(clientDetails);
  const {
    algoliaAssessmentsQuery = '',
    algoliaRequiredTagsQuery,
    algoliaStateQuery,
  } = getAlgoliaFilters({
    excludedAssessments,
    profileFilters,
  });
  const isAssessments = key === 'h-assessments';
  // 3rd param type boolean is use to indicate if filters is only for excluded topic
  const topicsQuery = !isAssessments
    ? getAlgoliaTopicsQuery(
        'filterTopics',
        false,
        clientDetails,
        clientLandingInfo,
      )
    : '';
  const resourcesWeightings = _get(
    clientDetails,
    'metadata.algoliaResourcesWeight',
  );
  algoliaIndex = AlgoliaClient.initIndex(
    getResourcesIndex(resourcesWeightings),
  );
  const audienceTypes = _get(
    clientLandingInfo,
    'audienceTypeCollection.items',
    [],
  );

  const audience = !_isEmpty(audienceTypes)
    ? audienceTypes
        .filter(item => _get(item, 'reviewStatus') === 'Accepted')
        .map(({ name }) => name)
    : [];
  let audienceQuery = '';
  let audienceOptionalQuery;
  if (!_isEmpty(audience)) {
    audienceOptionalQuery = audience.map(value => `audience:${value}`);

    const excludedAudiences = _flatten(
      audience
        .map(item =>
          _get(audienceTagsRelations[item], '0.exclude', []).map(
            exclude => exclude.id,
          ),
        )
        .filter(item => !_isEmpty(item)),
    ).filter(item => !audience.includes(item));

    if (!_isEmpty(excludedAudiences)) {
      audienceQuery = ' AND ';
      _forEach(excludedAudiences, (value, index) => {
        audienceQuery += `NOT audience:'${value}'`;
        if (index !== excludedAudiences.length - 1) {
          audienceQuery += ' AND ';
        }
      });
    }
  }
  const { alwaysShowInformationalLink } = getClientArticleSettings(
    clientDetails,
  );

  const clientExcludedResourceTypes =
    _get(clientDetails, 'excludeResourceTypes') || [];
  let wordCountQuery = '';
  const excludeResourceTypesFilters = _isEmpty(clientExcludedResourceTypes)
    ? ''
    : ` AND ${clientExcludedResourceTypes
        .map(type => {
          if (type === 'Articles' && alwaysShowInformationalLink) {
            // For Articles, when alwaysShowInformationalLink is true,
            // we want to include only Informational Links
            return `(NOT expandedType:"${type}" OR articlesSubtype:"Informational Link")`;
          }
          return `NOT expandedType:"${type}"`;
        })
        .join(' AND ')}`;

  const shouldFilterShortArticles = _get(
    clientDetails,
    'metadata.filterShortArticles',
    false,
  );
  if (shouldFilterShortArticles) {
    const articlesMinimumWordCount = getArticlesMinimumWordCount(
      featuresConfig,
      clientDetails,
    );
    wordCountQuery = ` AND wordCount > ${articlesMinimumWordCount}`;
  }
  const articlesQueryOnly = `AND ${getAlgoliaClientQuery(clientDetails)}`;
  const queryParams = {
    hitsPerPage: limit,
    filters: `reviewStatus:'Accepted' ${ratingCutOffQuery} ${topicsQuery} ${typeFilters} ${articlesQueryOnly} ${audienceQuery} ${algoliaAssessmentsQuery} ${algoliaRequiredTagsQuery} ${algoliaStateQuery} ${excludeResourceTypesFilters} ${wordCountQuery}`,
    optionalFilters: audienceOptionalQuery,
  };
  const response = await algoliaIndex.search('', queryParams);
  return response;
}

const typeFiltersMapping = {
  'h-all':
    "AND type:Apps OR type:Podcasts OR type:Videos OR type:Articles OR type:Books OR type:'Online Programs'",
  'h-apps': 'AND type:Apps',
  'h-books': 'AND type:Books',
  'h-videos': 'AND type:Videos',
  'h-podcasts': 'AND type:Podcasts',
  'h-articles': 'AND type:Articles',
  'h-programs': "AND type:'Online Programs'",
  'h-assessments': 'AND type:Assessments',
};

const getHomeResources = async (
  key,
  {
    limit,
    clientDetails,
    clientLandingInfo,
    audienceTagsRelations,
    profileFilters,
    featuresConfig,
  },
) => {
  const type = typeFiltersMapping[key];
  const data = await getAlgoliaData(
    limit,
    type,
    clientDetails,
    clientLandingInfo,
    audienceTagsRelations,
    profileFilters,
    key,
    featuresConfig,
  );
  return data;
};

export { getHomeResources };
