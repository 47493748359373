import { useEffect } from 'react';
import icon192 from '../../images/maskable_icon_x192.png';
import icon512 from '../../images/maskable_icon_x512.png';

const useManifest = () => {
  useEffect(() => {
    const manifestData = {
      name: 'CredibleMind',
      short_name: 'CredibleMind',
      description: 'Empower Everyone’s Mental Wellbeing.',
      icons: [
        {
          src: icon192,
          sizes: '192x192',
          type: 'image/png',
        },
        {
          src: icon512,
          sizes: '512x512',
          type: 'image/png',
        },
      ],
      start_url: '/',
      display: 'standalone',
    };

    const blob = new Blob([JSON.stringify(manifestData)], {
      type: 'application/json',
    });
    const manifestURL = URL.createObjectURL(blob);

    const link = document.createElement('link');
    link.rel = 'manifest';
    link.href = manifestURL;
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
      URL.revokeObjectURL(manifestURL);
    };
  }, []);
};

export default useManifest;
