import { createAction } from 'redux-actions';
import {
  GET_TAKEN_PRACTICES,
  GET_TAKEN_PRACTICES_SUCCESS,
  SET_PRACTICES_PROCESSING,
} from './constants';

export const getTakenPractices = createAction(GET_TAKEN_PRACTICES);
export const getTakenPracticesSuccess = createAction(
  GET_TAKEN_PRACTICES_SUCCESS,
);
export const setPracticesProcessing = createAction(SET_PRACTICES_PROCESSING);
