import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import client from 'utils/contentfulService';
import { filterBasedOnInsuranceTags } from 'containers/TopicCenter/services/getTopicLinkResources';

const getLinks = async (
  key,
  {
    clientId,
    clientGroupId,
    ctfLocaleFilter,
    profileTags,
    insuranceTags,
    insuranceOptions,
    show,
  },
) => {
  const clientIds = [clientId];
  if (clientGroupId) clientIds.push(clientGroupId);

  const excludedTagsFilter = {};
  if (!_isEmpty(profileTags)) {
    excludedTagsFilter['fields.excludedTags.sys.id[nin]'] = profileTags
      .map(tag => tag.id)
      .join(',');
  }

  try {
    const ctfPayload = {
      content_type: 'link',
      'fields.client.sys.id[in]': clientIds.join(','),
      'fields.reviewStatus': 'Accepted',
      ...excludedTagsFilter,
      ...ctfLocaleFilter,
    };

    if (show) {
      ctfPayload['fields.show[in]'] = show;
    }

    const response = await client.getEntries(ctfPayload);

    const filteredItems = filterBasedOnInsuranceTags(
      _get(response, 'items', []),
      insuranceTags,
      insuranceOptions,
    );

    const fieldsMapping = {
      callToAction: 'fields.callToAction',
      image: 'fields.image',
      url: 'fields.url',
      slug: 'fields.slug',
      reviewStatus: 'fields.reviewStatus',
      shortDescription: 'fields.subtitle',
      sys: 'sys',
      name: 'fields.title',
      homeOrder: 'fields.homeOrder',
      resourceOrder: 'fields.resourceOrder',
      exitDisclaimer: 'fields.exitDisclaimer',
      highlights: 'fields.highlights',
      contactInfo: 'fields.contactInfo',
      contactInfoType: 'fields.contactInfoType',
      state: 'fields.state',
      show: 'fields.show',
      disclaimerText: 'fields.disclaimerText',
      visibility: 'fields.assessmentResultsVisibility.user-resources',
      topics: 'fields.topics',
      themes: 'fields.themes',
      readMoreInfo: 'fields.readMoreInfo',
      nextSteps: 'fields.nextSteps',
      context: 'fields.context',
    };
    const prettyData = filteredItems.map(item =>
      Object.keys(fieldsMapping).reduce((res, field) => {
        res[field] = _get(item, fieldsMapping[field]);
        return res;
      }, {}),
    );
    return prettyData;
  } catch (error) {
    throw new Error(error);
  }
};

export default getLinks;
