import _get from 'lodash/get';

export const ARTICLE_TYPES = {
  NEWS: 'News',
  INFORMATION_LINK: 'Informational Link',
};

export const getClientArticleSettings = clientDetails => {
  const clientArticleSettings = _get(
    clientDetails,
    'metadata.articlesSettings',
    {},
  );

  const clientGroupArticlesSettings = _get(
    clientDetails,
    'clientGroup.metadata.articlesSettings',
    {},
  );
  const articlesSettings = {
    ...clientGroupArticlesSettings,
    ...clientArticleSettings,
  };

  return {
    alwaysShowInformationalLink: _get(
      articlesSettings,
      'alwaysShowInformationalLink',
      false,
    ),
    articlesMinimumWordCount: _get(
      articlesSettings,
      'articlesMinimumWordCount',
      null,
    ),
  };
};
