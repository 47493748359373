/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// IntersectionObserver polyfill for IE11
import 'intersection-observer';

import smoothscroll from 'smoothscroll-polyfill';
// import 'eventsource-polyfill';

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ApolloProvider } from '@apollo/client';
import _get from 'lodash/get';
import history, { getBasename } from 'utils/history';
import 'sanitize.css/sanitize.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import lvTheme from 'utils/theme';
import { isBot, filterSentry } from 'utils/stringUtils';
import { isLocalhost } from 'utils/networkUtils';
import Config from 'utils/getEnvConfig';
import GQlClient from 'utils/graphqlService';
import { ReactQueryConfigProvider } from 'react-query';

// firebase
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/analytics';
import 'firebase/compat/remote-config';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';
import { getFirebaseConfig, rrfConfig } from 'utils/firebaseService';
import { getLocalData } from 'utils/localDataStore';
import { HelmetProvider } from 'react-helmet-async';

// Import root app
import App from 'containers/MainWrapper';
// import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

/* eslint-disable import/no-unresolved, import/extensions */
import '!file-loader?name=[name].[ext]!./images/favicon.ico';
import '!file-loader?name=[name].[ext]!./images/cm-logo-blue.jpg';
import '!file-loader?name=[name].[ext]!./images/cm-logo.png';
import '!file-loader?name=[name].[ext]!./images/logo.png';
import 'file-loader?name=[name].[ext]!robots.txt';

import configureStore from './configureStore';

// Import CSS reset and Global Styles
import './app.css';
import './fonts/fonts.css';

if (!isBot()) {
  // eslint-disable-next-line global-require
  require('./tooltip.css');
}

smoothscroll.polyfill();

// Initialize firebase instance
firebase.initializeApp(getFirebaseConfig());
firebase.firestore().settings({ experimentalForceLongPolling: true });
firebase.functions();
firebase.analytics.isSupported().then(value => {
  if (value) firebase.analytics();
});

// Google Tag Manager
if (!isBot() && !isLocalhost) {
  Sentry.init({
    dsn: 'https://a16d6f5222d249b78271fe5901ea8d3f@sentry.io/1427288',
    environment: Config.ENV,
    release: Config.RELEASE,
    beforeSend(event, hint) {
      const error = hint.originalException;
      const finalError =
        typeof error === 'string' ? error : _get(error, 'message', '');
      if (finalError && filterSentry(finalError)) {
        return null;
      }
      return event;
    },
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: Config.SENTRY.TRACE_SAMPLE_RATE,
  });
  // https://www.simoahava.com/gtm-tips/fix-rogue-referral-problem-single-page-sites/
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    originalLocation:
      // eslint-disable-next-line prefer-template
      document.location.protocol +
      '//' +
      document.location.hostname +
      document.location.pathname +
      document.location.search,
  });
}
const queryConfig = {
  // Global
  refetchAllOnWindowFocus: false,
};

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history, firebase);
const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

// listen to history change and trigger parent handler if available
history.listen((location, action) => {
  const isPushAction = action === 'PUSH';
  if (
    typeof _get(window, 'xprops.onRouteChange', '') === 'function' &&
    isPushAction
  ) {
    const { pathname, search } = location;
    const lang = getLocalData('language');
    const enLangCodes = ['en', 'en-US'];

    const shouldAddLangParam =
      lang && !enLangCodes.includes(lang) && !search.includes('lang=');

    const langParam = shouldAddLangParam ? `lang=${lang}` : '';
    const connector = search ? '&' : '?';

    const finalParam = shouldAddLangParam
      ? `${search}${connector}${langParam}`
      : search;

    window.xprops.onRouteChange(`${pathname.replace(/^\/+/, '')}${finalParam}`);
  }
});

const MOUNT_NODE = document.getElementById('app');
const MainApp = Sentry.withProfiler(App);
const theme = createTheme(lvTheme());
/* eslint-disable react/jsx-props-no-spreading */
const render = () => {
  ReactDOM.render(
    <ReactQueryConfigProvider config={queryConfig}>
      <ApolloProvider client={GQlClient}>
        <CssBaseline />
        <Provider store={store}>
          <ReactReduxFirebaseProvider {...rrfProps}>
            <ConnectedRouter history={history}>
              <MuiThemeProvider theme={theme}>
                <HelmetProvider>
                  <MainApp />
                </HelmetProvider>
              </MuiThemeProvider>
            </ConnectedRouter>
          </ReactReduxFirebaseProvider>
        </Provider>
      </ApolloProvider>
    </ReactQueryConfigProvider>,
    MOUNT_NODE,
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['containers/Main'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render();
  });
}
if (process.env.NODE_ENV === 'a11y') {
  import('react-axe').then(axe => {
    axe.default(React, ReactDOM, 1000);
    render();
  });
} else {
  render();
}

const basename = getBasename();
if (basename && !window.location.pathname.startsWith(basename)) {
  const currentPathname = window.location.pathname;
  window.location.pathname = (basename + currentPathname).replace(/\/\//g, '/');
}
